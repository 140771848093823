<template>
  <div>
    <div>
      <el-form class="query"  inline size="small" v-model="params"  @submit.native.prevent="subQuery" >
        <el-form-item label="">
          <el-select v-model="params.type" clearable placeholder="所有类型" style="width: 100px">
            <el-option value="电影"></el-option>
            <el-option value="电视剧"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="params.maoyanStatus" clearable placeholder="猫眼" style="width: 120px">
            <el-option value="1" label="已绑定猫眼"></el-option>
            <el-option value="-1" label="未绑定猫眼"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="params.doubanStatus" clearable placeholder="豆瓣" style="width: 120px">
            <el-option value="1" label="已绑定豆瓣"></el-option>
            <el-option value="-1" label="未绑定豆瓣"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="params.imdbStatus" clearable placeholder="IMDB" style="width: 120px">
            <el-option value="1" label="已绑定IMDB"></el-option>
            <el-option value="-1" label="未绑定IMDB"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="params.baikeStatus" clearable placeholder="百度百科" style="width: 120px">
            <el-option value="1" label="已绑定百科"></el-option>
            <el-option value="-1" label="未绑定百科"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="params.baiduStatus" clearable placeholder="百度指数" style="width: 120px">
            <el-option value="1" label="已绑定百度指数"></el-option>
            <el-option value="-1" label="未绑定百度指数"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="params.iqiyiStatus" clearable placeholder="爱奇艺" style="width: 120px">
            <el-option value="1" label="已绑定爱奇艺"></el-option>
            <el-option value="-1" label="未绑定爱奇艺"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="params.tengxunStatus" clearable placeholder="腾讯视频" style="width: 120px">
            <el-option value="1" label="已绑定腾讯视频"></el-option>
            <el-option value="-1" label="未绑定腾讯视频"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="params.youkuStatus" clearable placeholder="优酷" style="width: 120px">
            <el-option value="1" label="已绑定优酷"></el-option>
            <el-option value="-1" label="未绑定优酷"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-select v-model="params.mgtvStatus" clearable placeholder="芒果" style="width: 120px">
            <el-option value="1" label="已绑定芒果"></el-option>
            <el-option value="-1" label="未绑定芒果"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-input placeholder="影视剧名称" v-model="params.name" clearable style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-input placeholder="企业名称" v-model="params.sampleName" clearable style="width: 150px"></el-input>
        </el-form-item>
        <el-form-item><el-button type="primary" native-type="submit">查询</el-button><el-button type="" @click="clickNew">添加</el-button></el-form-item>
      </el-form>
    </div>
    <masonry :cols="{default: 4, 1199: 3, 1300: 4}" :gutter="16">
      <movie style="margin-bottom: 20px" :movie="movie" v-for="movie in list" :key="movie.id" show-edit @edit="clickEdit" @delete="clickDelete">
      </movie>
    </masonry>
    <el-pagination
      @current-change="changeCurrent"
      @size-change="changeSize"
      :current-page.sync="page.current"
      :page-sizes="[20, 40, 60, 100]"
      :page-size="page.size"
      layout="sizes, prev, pager, next, total"
      :total="page.total">
    </el-pagination>
    <el-drawer
      :title="item.id ? '修改影视剧信息' : '添加影视剧'"
      :visible.sync="editVisible"
      destroy-on-close
      direction="rtl"
      :before-close="x"
      width="600px">
      <div style="padding: 0 20px 20px 10px;">
        <el-tabs v-model="tab">
          <el-tab-pane label="基础信息" name="first">
            <el-form ref="form0" :model="item" @submit.native.prevent="saveItem" label-width="120px" size="small">
              <el-form-item prop="title" label="名称" :rules="[{ required: true, message: '请输入名称'}]">
                <el-input v-model="item.title"></el-input>
              </el-form-item>
              <el-form-item label="类型" prop="type" :rules="[{ required: true, message: '请选择'}]">
                <el-select v-model="item.type" placeholder="请选择" style="width: 100px" >
                  <el-option value="电影"></el-option>
                  <el-option value="电视剧"></el-option>
                </el-select>
              </el-form-item>
              <div class="flex">
                <el-form-item prop="year" label="年份" class="flex-1" :rules="[{ required: true, message: '请输入'},  { type: 'number', min: 2017, max: 2030,  message: '请输入正确的日期大于2017' }]">
                  <el-input v-model.number="item.year"></el-input>
                </el-form-item>
                <el-form-item prop="releaseDate" label="上映日期" class="flex-1">
                  <el-date-picker v-model="item.releaseDate" style="width: auto;" value-format="timestamp"/>
                </el-form-item>
              </div>
              <div class="flex">
                <el-form-item prop="chupinCount" label="出品公司数" class="flex-1" :rules="[{ required: true, message: '请输入'}]">
                  <el-input-number v-model="item.chupinCount" :controls="false" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item prop="lhchupinCount" label="联合出品公司数" class="flex-1" :rules="[{ required: true, message: '请输入'}]">
                  <el-input-number v-model="item.lhchupinCount" :controls="false" :min="0"></el-input-number>
                </el-form-item>
              </div>
              <div class="flex">
                <el-form-item prop="faxingCount" label="发行公司数" class="flex-1" :rules="[{ required: true, message: '请输入'}]">
                  <el-input-number v-model="item.faxingCount" :controls="false" :min="0"></el-input-number>
                </el-form-item>
                <el-form-item prop="lhchupinCount" label="联合发行公司数" class="flex-1" :rules="[{ required: true, message: '请输入'}]">
                  <el-input-number v-model="item.lhfaxingCount" :controls="false" :min="0"></el-input-number>
                </el-form-item>
              </div>
              <div class="flex">
                <el-form-item prop="maoyanId" label="猫眼ID" class="flex-1" :rules="[{ pattern: /^\d+$/, message: '格式错误数字格式'}]">
                  <el-input v-model="item.maoyanId"></el-input>
                </el-form-item>
                <el-form-item prop="doubanId" label="豆瓣ID" class="flex-1" :rules="[{ pattern: /^\d+$/, message: '格式错误数字格式'}]">
                  <el-input v-model="item.doubanId"></el-input>
                </el-form-item>
              </div>
              <div class="flex">
                <el-form-item prop="imdbId" label="IMDB" class="flex-1" :rules="[{ pattern: /^tt\d+$/, message: '格式错误tt开头'}]">
                  <el-input v-model="item.imdbId"></el-input>
                </el-form-item>
                <div class="flex-1"></div>
              </div>
              <el-form-item prop="baikeUrl" label="百度百科"  :rules="[{ pattern: /^https:\/\/baike.baidu.com\/item\//, message: '链接错误'}]">
                <el-input v-model="item.baikeUrl"></el-input>
              </el-form-item>
              <el-form-item prop="baiduUrl" label="百度指数"  :rules="[{ pattern: /^https:\/\/index.baidu.com\//, message: '链接格式错误'}]">
                <el-input v-model="item.baiduUrl"></el-input>
              </el-form-item>
              <div class="flex">
                <el-form-item prop="iqiyiId1" label="爱奇艺ID" class="flex-1" :rules="[{ pattern: /^(\d|[a-z])+$/, message: '格式错误'}]">
                  <el-input v-model="item.iqiyiId1"></el-input>
                </el-form-item>
                <el-form-item prop="iqiyiId2" label="爱奇艺指数ID" class="flex-1" :rules="[{ pattern: /^\d+$/, message: '格式错误数字格式'}]">
                  <el-input v-model="item.iqiyiId2"></el-input>
                </el-form-item>
              </div>
              <el-form-item prop="tengxunUrl" label="腾讯链接" class="flex-1" :rules="[{ pattern: /^https:\/\/v.qq.com\/\S+.html/, message: '链接错误https://v.qq.com/**.html'}]">
                <el-input v-model="item.tengxunUrl"></el-input>
              </el-form-item>
              <el-form-item prop="youkuUrl" label="优酷链接" class="flex-1" :rules="[{ pattern: /^https:\/\/v.youku.com\/\S+.html/, message: '链接错误https://v.youku.com/**.html'}]">
                <el-input v-model="item.youkuUrl"></el-input>
              </el-form-item>
              <el-form-item prop="mgtvUrl" label="芒果tv链接" class="flex-1" :rules="[{ pattern: /^https:\/\/www.mgtv.com\/\S+.html/, message: '链接错误'}]">
                <el-input v-model="item.mgtvUrl"></el-input>
              </el-form-item>
              <el-form-item prop="douyinTopicId" label="抖音话题id" class="flex-1">
                <el-input v-model="item.douyinTopicId"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveItem">确 定</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="关联企业" name="second" v-if="item.id">
            <el-form ref="form1" :model="sample" label-width="120px" size="small">
              <el-form-item label="搜索公司">
                <el-input v-model="sample.title2" prefix-icon="el-icon-search"></el-input>
                <el-button size="mini" type="primary" @click="searchSamples">搜索公司</el-button>
              </el-form-item>
              <el-form-item label="公司" prop="sampleId" :rules="[{ required: true, message: '请选择'}]">
                <el-radio-group v-model="sample.sampleId">
                  <el-radio v-for="s in samples" :key="s.id"  :label="s.id">{{s.name}}</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="参与方式" prop="type" :rules="[{ required: true, message: '请选择'}]">
                <el-select v-model="sample.type" placeholder="请选择" style="width: 150px" >
                  <el-option value="chupin" label="出品"></el-option>
                  <el-option value="lhchupin" label="联合出品"></el-option>
                  <el-option value="faxing" label="发行"></el-option>
                  <el-option value="lhfaxing" label="联合发行"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="addMovieSample">添加参与公司</el-button>
              </el-form-item>
            </el-form>
            <el-table :data="item.samples" style="width: 100%"  stripe empty-text="没有公司">
              <el-table-column label="参与" width="50">
                <div slot-scope="scope">
                  <el-tag type="info" size="small">{{scope.row.type === 'faxing' ? '发行' : scope.row.type === 'lhfaxing' ? '联合发行' : scope.row.type === 'chupin' ? '出品' : scope.row.type === 'lhchupin' ? '联合出品' : '错误类型' }}</el-tag>
                </div>
              </el-table-column>
              <el-table-column label="企业">
                <el-link slot-scope="scope" @click="$showSample(scope.row.sampleId)"><i class="el-icon-link" style="margin-right: 3px; color: #888;"></i>{{scope.row.name}}</el-link>
              </el-table-column>
              <el-table-column label="猫眼" width="50">
                <div slot-scope="scope">
                  <a :href="`https://piaofang.maoyan.com/companydetail/${scope.row.maoyanId}`" target="_blank">猫眼</a>
                </div>
              </el-table-column>
              <el-table-column label="操作" width="80">
                <div slot-scope="scope">
                  <el-button type="warning" @click="clickRemoveSample(scope.row.msId)" size="mini">删除</el-button>
                </div>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <div v-if="!item.id" style="margin-left: 100px;">请添加完成后，再编辑关联企业</div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Movie from '../../components/Movie'
import listPageQuery from '../../mixins/list_page_query'
export default {
  components: { Movie },
  mixins: [listPageQuery],
  data () {
    return {
      url: '/movies',
      params: { pageSize: 40 },
      editVisible: false,
      status: [{ value: 1, label: '播放' }, { value: -1, label: '未播放' }, { value: 0, label: '未知' }],
      item: {},
      tab: 'first',
      sample: {},
      samples: []
    }
  },
  methods: {
    x (done) {
      this.$refs.form0.resetFields()
      done()
    },
    getItem (id) {
      this.$request.get(`/movies/${id}`)
        .then(res => {
          this.item = res
        })
    },
    clickDelete (id) {
      this.$confirm('请确认', '确定要删除吗')
        .then(() => this.$request.delete(`/movies/${id}`))
        .then(() => {
          this.$message({ type: 'success', message: '删除成功' })
          this.refresh()
        })
    },
    clickEdit (item) {
      this.item = Object.assign({}, item)
      this.editVisible = true
    },
    clickNew () {
      this.item = {}
      this.editVisible = true
    },
    saveItem () {
      const { id, ...data } = this.item
      this.$refs.form0.validate()
        .then(() => id ? this.$request.put(`/movies/${id}`, data) : this.$request.post('/movies', data))
        .then(() => {
          this.refresh()
          this.$message({ type: 'success', message: id ? '保存成功！' : '添加成功！' })
        })
    },
    clickRemoveSample (id) {
      this.$confirm('确认删除吗?', '请确认')
        .then(() => this.$request.delete(`/movies/${this.item.id}/samples/${id}`))
        .then(() => {
          this.refresh()
          this.getItem(this.item.id)
        })
    },
    addMovieSample () {
      this.$refs.form1.validate()
        .then(() => {
          const { type, sampleId } = this.sample
          return this.$request.post(`/movies/${this.item.id}/samples`, { type, sampleId })
        })
        .then(res => {
          this.refresh()
          this.getItem(this.item.id)
          console.log(res)
        })
    },
    searchSamples () {
      const name = this.sample.title2
      this.$request.get('/samples', { params: { name, pageSize: 5 } })
        .then(res => {
          this.samples = res.list
        })
    }
  },
  mounted () {
    this.requestData({ pageSize: 40, current: 1 })
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
    .flex-1 {  flex-grow: 1; flex-shrink: 0; flex-basis: 50% }
  }
  .query {
    .el-form-item--small.el-form-item { margin-bottom: 8px; }

  }
</style>
